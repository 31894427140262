<template>
    <v-container>
        <v-text-field
            v-model="currentModel.NomeGruppo"
            label="Nome Cluster"
            prepend-icon="mdi-database"
        ></v-text-field>

        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
            <Stores
                :farmId="currentModel.IdConsorzio"
                :stores.sync="currentModel.IdClienti"
            ></Stores>
        </div>
        <v-row>
            <v-col cols="12" class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="updateCluster"
                    :disabled="!isFormValid || !isChanged"
                >
                    Conferma
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { checkObjectDiff } from "../../../utils/utils";

export const Stores = () => import("../components/StoresSelector");

export default {
    props: {
        model: {
            type: Object,
            required: true
        }
    },

    components: {
        Stores
    },

    data: () => ({
        currentModel: {},
        isChanged: false
    }),

    watch: {
        currentModel: {
            handler: function(val) {
                this.isChanged = checkObjectDiff(
                    this.model,
                    this.currentModel
                );
            },
            deep: true,
            immediate: true
        }
    },

    computed: {
        isFormValid() {
            return (
                this.currentModel.NomeGruppo.length !== 0 &&
                this.currentModel.IdClienti.length !== 0
            );
        }
    },

    methods: {
        updateCluster() {
            this.$emit("saveData", this.currentModel);
        }
    },

    beforeMount() {
        this.currentModel = JSON.parse(JSON.stringify(this.model));
    }
};
</script>

<style></style>
